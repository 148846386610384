import HttpFactory from './factory';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';
import type {Block} from '~/models/Block';
class BlockRepository extends HttpFactory {
    private RESOURCE = '/blocks';

    async show(uuid: string): Promise<ApiResourceResponse<Block>> {
        return await this.getJson(`${this.RESOURCE}/${uuid}`);
    }
}

export default BlockRepository;